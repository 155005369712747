<template>
  <ui-component-modal
    :isSaving="isSaving"
    :modalTitle="$t('Components.Vouchers.ModalEditBasicData.Main_Title')"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :onClickSave="createNewVoucher"
  >
    <template v-slot:content>
      <table class="table is-fullwidth is-striped">
        <tbody>
          <tr>
            <td>Type</td>
            <td>
              <div v-if="voucherType > -1">{{ getVoucherTypeName() }}</div>
              <div
                v-if="voucherType === -1"
                class="select"
              >
                <select v-model="newVoucher.VoucherType">
                  <option
                    :value="-1"
                    :selected="voucherType.Id === -1"
                  >
                    Select a type
                  </option>
                  <option
                    v-for="(voucherType, index) in voucherTypes"
                    :key="index"
                    :value="voucherType.Id"
                  >
                    {{ voucherType.Name }}
                  </option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>{{ $t('Components.Vouchers.CreateVoucher.Label_Name') }}</td>
            <td>
              <input
                type="text"
                v-model="newVoucher.Name"
                class="input"
              />
            </td>
          </tr>
          <tr v-if="voucherType !== 1">
            <td>{{ $t('Components.Vouchers.CreateVoucher.Label_Code') }}</td>
            <td>
              <input
                type="text"
                v-model="newVoucher.Code"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('Components.Vouchers.CreateVoucher.Label_Description') }}
            </td>
            <td>
              <textarea
                v-model="newVoucher.Description"
                class="textarea"
                maxlength="6000"
              ></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import voucherProvider from '@/providers/voucher'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    channelId: {
      type: Number,
      default: 0,
    },

    locationId: {
      type: Number,
      default: 0,
    },

    voucherType: {
      type: Number,
      default: -1,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
      newVoucher: null,
      voucherTypes: [],
    }
  },

  created() {
    this.voucherTypes.push({ Id: 0, Name: 'Action' })
    this.voucherTypes.push({ Id: 1, Name: 'Package' })
    this.voucherTypes.push({ Id: 2, Name: 'Voucher' })
    this.voucherTypes.push({ Id: 3, Name: 'Rate' })
    this.voucherTypes.push({ Id: 4, Name: 'Credit' })

    this.newVoucher = this.setupNewVoucherData()
  },

  methods: {
    getVoucherTypeName() {
      let voucherType = this.voucherTypes.find(
        (vt) => vt.Id === this.voucherType
      )
      if (voucherType) {
        return voucherType.Name
      }
      return ''
    },

    cancelCreateVoucher() {
      this.newVoucher = this.setupNewVoucherData

      this.$emit('cancelCreateVoucher')
    },

    createNewVoucher() {
      this.isSaving = true
      voucherProvider.methods
        .createVoucher(this.newVoucher)
        .then((response) => {
          this.isSavingSuccess = true
          setTimeout(() => {
            this.$emit('voucherCreated', response.data)
            this.onClickCancel()
          }, 1500)
        })
        .catch((error) => {
          //console.log(error.response)
          self.isSavingError = true
        })
        .finally(() => {
          this.isSaving = false
        })
    },

    generateCode() {
      var text = ''
      var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

      for (var i = 0; i < 6; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
      }

      return text
    },

    setupNewVoucherData() {
      return {
        ChannelId: this.channelId,
        LocationId: this.locationId,
        VoucherType: this.voucherType,
        Name: 'New voucher',
        Code: this.generateCode(),
        Description: '',
        Type: this.voucherType,
      }
    },
  },
}
</script>

<style></style>
